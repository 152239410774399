import React from 'react';

import { IconTile } from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import FolderClosedIcon from '@atlaskit/icon/core/folder-closed';

export const Folder20Icon = (props: GlyphProps) => {
	const label = props.label || '';
	return (
		<IconTile
			key="folder"
			label={label}
			appearance="magentaBold"
			shape="square"
			size="16"
			icon={FolderClosedIcon}
			testId={props.testId}
		/>
	);
};
