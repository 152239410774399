import React from 'react';

import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import UploadIcon from '@atlaskit/icon/core/upload';
import type { IconColor } from '@atlaskit/tokens/css-type-schema';

type Import24IconProps = {
	primaryColor?: IconColor;
	label?: string;
} & GlyphProps;

export const Import24Icon = (props: Import24IconProps) => {
	const primaryColor = props.primaryColor || token('color.icon');
	const label = props.label || '';

	return <UploadIcon label={label} color={primaryColor} />;
};
