import React from 'react';

import { IconTile } from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import CommentIcon from '@atlaskit/icon/core/comment';

export const Comment16Icon = (props: GlyphProps) => {
	const label = props.label || '';
	return (
		<IconTile
			key="comment"
			label={label}
			appearance="greenBold"
			shape="square"
			size="16"
			icon={CommentIcon}
			testId={props.testId}
		/>
	);
};
