import React from 'react';

import { IconTile } from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import SmartLinkListIcon from '@atlaskit/icon/core/smart-link-list';

export const Database24Icon = (props: GlyphProps) => {
	const label = props.label || '';
	return (
		<IconTile
			key="database"
			label={label}
			appearance="purpleBold"
			shape="square"
			size="24"
			icon={SmartLinkListIcon}
			testId={props.testId}
		/>
	);
};
