import React from 'react';

import type { GlyphProps } from '@atlaskit/icon/types';
import WhiteboardIcon from '@atlaskit/icon/core/whiteboard';
import { IconTile } from '@atlaskit/icon';

export const Whiteboard24Icon = (props: GlyphProps) => {
	const label = props.label || '';
	return (
		<IconTile
			key="whiteboard"
			label={label}
			appearance="tealBold"
			shape="square"
			size="24"
			icon={WhiteboardIcon}
			testId={props.testId}
		/>
	);
};
