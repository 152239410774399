import React from 'react';

import { IconTile } from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import DatabaseIcon from '@atlaskit/icon/core/database';

export const Database16Icon = (props: GlyphProps) => {
	const label = props.label || '';
	return (
		<IconTile
			key="database"
			label={label}
			appearance="purpleBold"
			shape="square"
			size="16"
			icon={DatabaseIcon}
			testId={props.testId}
		/>
	);
};
