import React from 'react';

import { IconTile } from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import LinkIcon from '@atlaskit/icon/core/link';

export const Embed24Icon = (props: GlyphProps) => {
	const label = props.label || '';
	return (
		<IconTile
			key="embed"
			label={label}
			appearance="grayBold"
			shape="square"
			size="24"
			icon={LinkIcon}
			testId={props.testId}
		/>
	);
};
